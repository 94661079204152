@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-Regular.ttf');
}

:root {
  --bg-yellow: yellow;
  --bg-orange: #d0ab26;
  --bg-red: #ff3668;
  --bg-blue: #4babcc;
  --bg-green: #52c148;
  --bg-size: 500%;
}

html, body {
  height: 100%;
  color: white;
  font-family: "Inter", Helvetica, Arial, sans-serif;
  margin: 0px;
  overflow: hidden;
  background-color: #263238;
  background: linear-gradient(90deg, var(--bg-red), var(--bg-orange), var(--bg-blue), var(--bg-red));
  background-size: var(--bg-size) 400%;
  animation: gradient 200s linear infinite;
  transform: translateZ(0);
}

#root {
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: var(--bg-size) 50%;
  }
}

h1, h2 {
  font-size: 36px;
  margin: 5px;
}

h2 {
  font-size: 24px;
}

section {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

section ul {
  padding: 0px;
}

section ul li {
  display: inline-block;
  list-style-type: none;
  font-size: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

section ul li a {
  transition: color 0.2s ease;
  text-decoration: none;
  color: white;
}

section ul li a:hover {
  color: #DDD;
}

.falling-snow {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.falling-snow span {
  display: inline-block;
  width: 20px;
  height: 21px;
  margin: -100px 60px 54px -8px;
  background: url("assets/images/snowflake.png");
  animation: falling-snow-keyframes 12s infinite cubic-bezier(0.35, 0.45, 0.39, 0.29);
}

.falling-snow span:nth-child(5n+5) {
  animation-delay: 1.3s;
}

.falling-snow span:nth-child(3n+2) {
  animation-delay: 1.5s;
}

.falling-snow span:nth-child(2n+5) {
  animation-delay: 1.7s;
}

.falling-snow span:nth-child(3n+10) {
  animation-delay: 2.7s;
}

.falling-snow span:nth-child(7n+2) {
  animation-delay: 3.5s;
}

.falling-snow span:nth-child(4n+5) {
  animation-delay: 5.5s;
}

.falling-snow span:nth-child(3n+7) {
  animation-delay: 8s;
}

@keyframes falling-snow-keyframes {
  0% {
    transform: translate(0vw, 0vh) rotateZ(0deg);
  }

  75% {
    transform: translate(7.5vw, 75vh) rotateZ(270deg);
  }

  100% {
    transform: translate(10vw, calc(100vh + 100px)) rotateZ(360deg);
  }
}
